<template>
  <div id="developer">
    <div class="banner">
      <p>开发者</p>
      <p>
        基于web环境的集成开发环境 <br />敏捷配置组装、低代码研发，快速响应交付
      </p>
    </div>
    <div class="container">
      <div class="floor_1 floor">
        <div class="floor1_title">
          <div class="floor1_t1">MIP网络集成平台</div>
          <div class="floor1_t2">各信息系统实现快速无缝集成</div>
        </div>
        <div class="clearfix floor1_main">
          <div class="left">
            <div class="l_text">
              <p class="p1">
                统一技术平台支撑，统一服务开放接入，统一系统运营管理及全自动化系统运维。
              </p>
              <br />
              <p class="p2">
                基于浏览器集成工具，在线设计服务流程、BMP业务流程，在线组装具有业务含义应用系统。
              </p>
              <br />
              <p class="p3">
                基于服务总线和消息总线的多类型数据开放接入，结合数据运算流程编排组装及配置化的数据运算调度方式，可快速实现企业的多个子系统间的互联互通，无缝集成。
              </p>
              <br />
              <p class="p4">
                网格服务、大数据架构，具有高频次调用与海量数据处理能力。服务与应用灵活部署，灵活扩展。
              </p>
            </div>
            <div class="btn">咨询获取解决方案</div>
          </div>
          <div class="right">
            <img src="~assets/image/developer/f1_1.png" alt="" />
          </div>
        </div>
      </div>
      <div class="floor_2 floor">
        <div class="floor2_title">
          <div class="floor2_t1">TDP技术开发平台</div>
          <div class="floor2_t2">快速构建企业业务中台</div>
        </div>
        <div class="clearfix floor2_main">
          <div class="left">
            <img src="~assets/image/developer/f2_1.png" alt="" />
          </div>
          <div class="right">
            <div class="l_text">
              <p class="p1">
                从需求到集成发布全流程敏捷化管理，研发过程与研发成果闭环管控，降低协同研发成本，实现快速交付目标。
              </p>
              <br />
              <p class="p2">
                基于WEB的集成开发环境，前端、服务在线可视化开发与调试，服务流程与数据运算流程自定义在线编排，一键发布部署。
              </p>
              <br />
              <p class="p3">
                从数据建模到应用功能的全系列开发组件，统一技术底层容器与服务引擎，让研发人员更关注于业务，研发效率提高60%以上。
              </p>
              <br />
              <p class="p4">
                支持多类型的原生开源系统，基于微服务架构，模块独立、高度复用、灵活组合扩展，可快速响应业务发展需求。
              </p>
            </div>
            <div class="btn">咨询获取解决方案</div>
          </div>
        </div>
      </div>
      <div class="floor_3 floor">
        <div class="floor3_title">
          <div class="floor3_t1">DDP数据开发平台</div>
          <div class="floor3_t2">构建企业数据中台与数据应用系统</div>
        </div>
        <div class="clearfix floor3_main">
          <div class="top">
            <img src="~assets/image/developer/f3_1.png" alt="" />
          </div>
          <div class="bottom">
            <div class="b_left">
              <p>
                实现数据集中治理，全维打通融合，分域管理。实现数据与业务的闭环，形成企业数据资产，适用经营与运营中的实时监控、即席查询，分析诊断决策与智能应用。
              </p>
              <p>
                从数据交换、数据治理、数据开发、数据资产可配置化或低代码开发工具集，实现数据全生命周期链路的应用处理，快速实现企业数据中台。
              </p>
            </div>
            <div class="b_right">
              <p>
                可视化界面操作，服务在线开发、全SQL表达，运算流程在线编排、内置丰富的物流行业数据模型与展示组件，降低数据使用门槛与人员能力要求，提高开发效率50%以上。
              </p>

              <p>
                大数据架构，支持多类型的数据源，支持高频次、高吐量、海量数据的处理。支持定时、事件驱动多类型的运算调度策略，支持批量与流式等多种运算方式。
              </p>
            </div>
          </div>
          <div class="btn">咨询获取解决方案</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Developer",
};
</script>
<style lang="scss" scoped>
#developer {
  width: 100%;
  font-family: Microsoft YaHei;
  .banner {
    position: relative;
    width: 100%;
    height: 3.634rem;
    background: url("../assets/image/developer/b_1.png") no-repeat;
    background-size: cover;
    padding: 0.91rem 0 0 3.2rem;
    p:nth-of-type(1) {
      font-size: 0.36rem;
      font-weight: bold;
      color: #ffffff;
    }
    p:nth-of-type(2) {
      margin-top: 0.35rem;
      font-size: 0.16rem;
      font-weight: 400;
      color: #ffffff;
      line-height: 0.3rem;
    }
  }
  .container {
    [class^="floor_"] {
      width: 100%;
      padding-top: 0.01rem;
      [class$="_title"] {
        text-align: center;
        font-size: 0.4rem;
        font-weight: bold;
        margin-top: 1.2rem;
        color: #383838;
        [class$="_t1"] {
          font-size: 0.4rem;
          font-weight: bold;
          color: #232323;
        }
        [class$="_t2"] {
          height: 0.2rem;
          margin-top: 0.32rem;
          font-size: 0.18rem;
          font-weight: 400;
          color: #323333;
        }
      }
      [class$="_main"] {
        display: flex;
        margin: 0.325rem auto 0;
        width: 12.8rem;
      }
    }

    .floor_1 {
      height: 7.5rem;
      .floor1_main {
        .left {
          width: 5.1rem;
          height: 2.3rem;
          margin-top: 0.43rem;
          .l_text {
            font-size: 0.14rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #606266;
            line-height: 0.24rem;
          }
          .btn {
            width: 1.59rem;
            height: 0.32rem;
            margin-top: 0.38rem;
            background: #1562ec;
            border-radius: 0.02rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 0.32rem;
            cursor: pointer;
          }
        }
        .right {
          margin-left: 1.61rem;
          margin-top: 0.07rem;
          img {
            width: 6.11rem;
            height: 4.33rem;
          }
        }
      }
    }
    .floor_2 {
      height: 7.5rem;
      background: #f7f7f7;
      .floor2_main {
        .right {
          float: left;
          width: 5.1rem;
          height: 2.3rem;
          margin-top: 0.13rem;
          margin-left: 0.85rem;
          .l_text {
            font-size: 0.14rem;
            font-family: Source Han Sans CN;
            font-weight: 300;
            color: #606266;
            line-height: 0.24rem;
          }
          .btn {
            width: 1.59rem;
            height: 0.32rem;
            margin-top: 0.38rem;
            background: #1562ec;
            border-radius: 0.02rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 0.32rem;
            cursor: pointer;
          }
        }
        .left {
          float: left;
          img {
            width: 6.8rem;
            height: 4.5rem;
          }
        }
      }
    }
    .floor_3 {
      height: 10.7rem;
      .floor3_main {
        flex-direction: column;
        margin-bottom: 0.3rem;
        .top {
          img {
            width: 13.21rem;
            height: 4.74rem;
          }
        }
        .bottom {
          display: flex;
          margin-top: 0.27rem;
          font-size: 0.14rem;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #606266;
          line-height: 0.24rem;
          .b_left,
          .b_right {
            width: 5.79rem;
            height: 1rem;
            p:nth-child(1) {
              margin-bottom: 0.08rem;
            }
          }
          .b_left {
            margin-right: 1.5rem;
          }
        }
        .btn {
          width: 1.59rem;
          height: 0.32rem;
          margin-top: 0.55rem;
          background: #1562ec;
          border-radius: 0.02rem;
          font-size: 0.14rem;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 0.3rem;
          cursor: pointer;
        }
      }
    }
  }
}
</style>